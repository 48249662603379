<template>
  <div class="opage">
    <Head />
    <div class="bannerRow">
      <div class="backgroundBox"></div>
      <div class="bannerCon">
        <h1>Atención al cliente</h1>
        <p class="banP">¿En qué podemos ayudarte?</p>
        <div class="helpList">
          <div class="helpType">
            <div class="helpCon" @click="$fun.clickJs()">
              <img
                src="https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/6419683c5a4d2.png"
                alt=""
              />
              <h3>Habla con un miembro de nuestro equipo de agentes.</h3>
              <p>
                Te ayudaremos y asesoraremos en todo el proceso de importación
                desde China.
              </p>
              <button>Contactar con un agente</button>
            </div>
          </div>
          <div class="helpType">
            <div class="helpCon" @click="$fun.routerToPage('/centro-de-ayuda')">
              <img
                src="https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/641968748e00b.png"
                alt=""
              />
              <h3>Consulta nuestro centro de ayuda para resolver dudas.</h3>
              <p>
                Visita nuestro centro de ayuda para encontrar las respuestas que
                buscas.
              </p>
              <button>Ir al centro de ayuda</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contactUs">
      <h1>Métodos de contacto</h1>
      <div class="aListOfContactDetails">
        <div class="oneContact">
          <img
            src="../../assets/foot-img/atencion-al-cliente/lian1.svg"
            alt=""
          />
          <h3>Correo electrónico:</h3>
          <p>soporte@rakumart.es</p>
        </div>
        <!-- <div class="oneContact">
          <img
            src="../../assets/foot-img/atencion-al-cliente/lian2.svg"
            alt=""
          />
          <h3>Escríbenos por Whastapp:</h3>
          <p>+34 93 046 23 64</p>
        </div>
        <div class="oneContact">
          <img
            src="../../assets/foot-img/atencion-al-cliente/lian3.svg"
            alt=""
          />
          <h3>Llámanos:</h3>
          <p>93 046 23 64</p>
        </div> -->
      </div>
    </div>
    <div class="platformInformation">
      <h3>Central España</h3>
      <p><b>Elosung Europe S.A.</b></p>
      <p>Carrer de Francesc Teixidó, 43, 08918 Badalona, Barcelona, España</p>
    </div>
    <div class="platformInformation">
      <h3>Central China</h3>
      <p><b>Rakumart China</b></p>
      <p>
        Building 2, Floors 3-5, No.127 Chunhan Road, Beiyuan Sub-district, Yiwu
        City, Zhejiang Province, China
      </p>
    </div>
    <Foot />
  </div>
</template>
<script>
import Head from "../../components/head/HomePageTop.vue";
import Foot from "@/components/foot/Foot.vue";
export default {
  data() {
    return {};
  },
  components: {
    Head,
    Foot,
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
$pageWidth: 1100px;
.opage {
  background-color: white;
}
.bannerRow {
  margin-top: -20px;
  margin-bottom: 70px;
  position: relative;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  .backgroundBox {
    background-image: url("https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/64195b9c03b94.jpg");

    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    background-position: center center;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-size: cover;
  }
  .bannerCon {
    width: 919px;
    margin-right: 179px;
    padding: 10px;
    h1 {
      font-size: 46px;
      font-weight: 700;
      line-height: 1.2em;
      margin-bottom: 20px;
    }
    .banP {
      font-size: 19px;
      line-height: 1;
      color: #1a289d;
      margin-bottom: 40px;
    }
    .helpList {
      z-index: 1;
      position: relative;
      display: flex;
      justify-content: space-between;
      .helpType {
        width: 447px;
        padding: 10px;
        .helpCon {
          height: 100%;
          border-radius: 6px 6px 6px 6px;
          background-color: white;
          box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.18);
          transition: 0.2s;
          cursor: pointer;
          &:hover {
            box-shadow: 0px 3px 10px -2px rgba(0, 0, 0, 0.25);
          }
          border: solid 1px #eaeaea;
          padding: 55px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          * {
            text-align: center;
          }
          img {
            width: 47px;
            height: 47px;
            margin-bottom: 21px;
          }
          h3 {
            font-size: 22px;
            font-weight: 500;
            line-height: 1.4em;
            margin-bottom: 15px;
          }
          p {
            font-size: 16px;
            margin-bottom: 30px;
            line-height: 1.8em;
          }
          button {
            min-width: 262px;
            min-height: 59px;
            padding: 20px 40px;
            font-size: 15px;
            font-weight: 600;
            color: #ffffff;
            background-color: #1a73e8;
            border-color: #1a73e8;
            border-width: 2px;
            border-radius: 3px;
            &:hover {
              background-color: white;
              color: #1a73e8;
            }
          }
        }
      }
    }
  }
}
.contactUs {
  width: $pageWidth;
  margin: 0 auto 50px;
  h1 {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 21px;
    line-height: 29px;
  }
  .aListOfContactDetails {
    display: flex;
    .oneContact {
      border-radius: 6px;
      border-style: solid;
      //flex: 1;
      flex: 0 0 366px;
      border-width: 1px 1px 1px 1px;
      border-color: #e4e4e4;
      transition: background 0.3s, border 0.3s, border-radius 0.3s,
        box-shadow 0.3s;
      margin: 0px 10px 0px 10px;
      padding: 30px 30px 30px 30px;
      img {
        width: 25px;
        height: 25px;
        margin-bottom: 15px;
      }
      h3 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        line-height: 33px;
        margin-bottom: 14px;
      }
    }
  }
}
.platformInformation {
  width: $pageWidth;
  margin: 0 auto 20px;
  &:last-child {
    margin-bottom: 50px;
  }
  padding: 10px;
  h3 {
    font-size: 21px;
    font-weight: 500;
    line-height: 1.4em;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 0.9rem;
    font-size: 16px;
    line-height: 33px;
    margin-bottom: 14px;
  }
}
</style>
